.education-update-container{
    input{
        width: 100% !important;
    }
    .error{
        padding: 5px;
        color: red;
        font-size: 11px;
      }
    border-radius: 10px;
  p{
    padding-top: 1rem;
      font-weight: 600;
  }
    max-width: 850px;
    background-color: #ffffff;
    h4{
        padding: 12px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;
    }
    form{
        .date-row{
            padding: 16px;
            .form-control{
                margin: 6px 0;
            }
        }
        padding: 24px;
        .date-picker{
            margin-bottom: 1rem;
            width: 100%;
            height: 40px;
        //   border-color:  #ced4da;
          border:0.5px solid #ced4da;
          border-radius: 5px;
        }
        label{
            // color: #707981;
            color: #000000;
            padding-right: 16px;
            padding-top: 10px;
        }
        .form-control-file{
            color: #707981;

        }
        .form-group{
            margin-bottom: 1.5rem;
            
        .form-label{
            padding-right: 16px;

            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 4px;
        }
       
        .form-input-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
        .form-textarea-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
    }
    .button-delete{
        float: left;
        background-color: transparent;
        color: rgba(0,0,0,.6);
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.6);
        padding: .2rem 0.8rem;
        border-radius: 2px;
        border:  0.4px solid #969292;
        margin-top: 24px;
        width: 100%;
        font-weight: 600;
        &.save{
            background-color: #0073b1;
            color: #ffffff;
            float: right;
            border: none;
            box-shadow: none;
        }
    }
    }
    &.wizard-one{
        max-width: 50%;
        margin-top: -61px;
}
    
}
