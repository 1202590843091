.outer-student-container{
        width: 100%;
        background-color: #fff;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 1rem;
        p{
            padding-right: 0 !important;
        }

         margin-top: 10px;
    // &.show-hover{
    // :hover{
    //         transition: transform .2s;
    //         transform: scale(1.01);
            
            
    //     }
    // }
         &.hide-hover{
            :hover{
                transition: none;
                transform: none;
            
            }
        }

    .cards{
        .demo-class{
            background-color: #fff !important;
            padding-left: 32px;
                &.eligible{
                    background-color: #D7F3DF!important;

            }
                &.not-eligible{
                    background-color: #f9e7e7 !important;

            }   
        }
    cursor: pointer;
    padding: 0px;
    border: 1px solid rgba(0,0,0,.125) !important;
    border-top: none !important;
    .zbexpand {
        &::before {
            font: normal normal normal 14px/1 FontAwesome;
            content: "-";
            position: absolute;
            top: 15px;
            left: 10px;
        }
    }

h6{
    padding: 1px;
    margin-top: 7px;
    font-weight: 400;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 0;
   
    // float: left;
}
.eligibility{
    padding: 3px 4px !important;
  }
  .subscriber-green{
      background-color:#28a745;
      margin-top: 7px;
      font-size: 13px;
      font-weight: 500;
  }
  .subscriber-amber{
    background-color:#ffc107;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 500;
}
.subscriber-red{
    background-color:#dc3545;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 500;
}
.lead-green{
    background-color:#28a745;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 500;
}
.lead-amber{
  background-color:#ffc107;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 500;
}
.lead-red{
  background-color:#dc3545;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 500;
}

    p {
        font-size: 13px;
        margin-top: 11px;
    }
    .university-col {
        font-size: 1.25rem;
        padding: 2.5rem;
    }
    .form-control{
        &.modal-email{
            padding-top: 1.2rem !important;
            padding-bottom: 1.2rem !important;
            padding-left: 1rem !important;
            box-shadow: none !important;
            border-radius: 0.375rem !important;
            border: none !important;
            background-color: #ffffff !important;
            padding-right: 1rem;
        }
    }
    .send-button{
        font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #0e1743;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        float: right;
        color: #ffffff;
        margin-top: 10px;
    }
    .assign-button{
        color:#ffffff;
        background-color: #e42126;
        background-image: none;    
        font-size: 12px;
        box-shadow: none;
        padding: 8px 14px;
    }
    .review-button{
        color:#ffffff;
        background-color: #3c8c46;
        background-image: none;
        box-shadow: none;
        font-size: 12px;
        box-shadow: none;
        padding: 9px 18px;
    }
    .interview-name{
        font-size: 11px;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        float: left;
        width: 75px;
        margin-top: 13px;
    }
    .emailSend{
        margin: 0 7px 4px;
    }
    .cross-icon{
        font-size: 14px;
        color: #e20033;
        cursor: pointer;
        margin-top: 12px;
        margin-left: 10px;
        &.plus{
            color: #3c8c46;
          
        }
        &.mail{
            color: #007bff;
        }
      
        &.video{
            color: #396f6d;
        }
        &.edit{
            color: #9e701b;
            margin-top: 11px;
            }
        &.id-badge{
            color: #9e701b;
            margin-top: 11px;
        }
    }
    
    .button-conatiner {
        margin-top: 10px;
        text-align: center;
        .report-button {
            // background-color: #e42126;
            font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        color: #ffffff;
        }
    }

 }
 .date-hide{
    display: none; 
        //  display: block !important;
        -webkit-transition: background .1s ;
        transition: width 2s ease;
        -moz-transition: background .1s linear;   
        -ms-transition: background .1s linear;   
        -o-transition: background .1s linear;   
        transition: background .1s linear; 

     
 }
 .cards:hover + .date-hide {
    display: block;
    color:  #5c7080;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    transition: width .15s ease;
    padding-left: 25px;
    padding-top: 15px;
    h6{
        font-size: 20px;
    }

  }
 .modal {
    background-color: rgba(000, 000, 000, .6);

    padding: 7px !important;
    &.show {
        display: block !important;
        opacity: 1 !important;
    }
    .modal-header {
        background: #0e1743;
        text-transform: uppercase;
        padding:0 !important;
        h6 {
            color: #fff;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            margin-top: 13px;
            
        }
        .close {
            cursor: pointer;
            color: #fff;
            opacity: .8;
            font-size: 1em;
            font-weight: 400;
            margin-top: -10px;
            padding: 15px;
            font-size: 1em;
            padding: 2rem 2rem;
            &:hover,
            &:focus {
                opacity: 1;
                color: #fff !important;
                border: none !important;
                background-color: transparent;
                box-shadow:none !important;

            }
        }
    }
}
@media (min-width: 992px){
    .modal-lg {
        max-width: 800px;
    }
}
.remove-padding{
    padding: 0;
    &.name-container{
        background-color: #f1f5f8;
        margin-right: 7px;
    }

}
.name-container{
    background-color: #f1f5f8;
}
.video-react .video-react-video{
    max-height: 450px !important;
}
.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3{
    padding-top: 100% !important;
}
}