.header-new-container{
    .right-side{
        text-align: right;
        padding: 10px 1rem;
        .user-icon{
            font-size: 25px;
            color: #1f45aa;
            cursor: pointer;
           
        }
    }
   
}
.profile-user{
   
    .notification-show{
        position: fixed;
        height: 250px;
        width: 220px;
        right:-17px;
        background-color: #15329A;
        border-radius: 5px;
        box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.4);
        z-index: 1;
      
        p{
            padding: 10px;
            text-align: center;
            border-bottom: 0.2px solid #2f5ea9;
            color: #ffffff;
            margin: 0;
            cursor: pointer;
            &:hover{
                background-color: #2f5ea9;
            }
        }
        .title-notification{
            text-align: center;
            border-radius: 5px 5px 0px 0px;
            padding: 10px;
            background-color: #0e1743;
            color: #ffffff;
          }
          .notification-hide{
            opacity:0;
          }
      }
    
     
     
}
.bp3-popover-arrow{
    left:-11px !important;
}