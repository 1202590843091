.text-formatter {
    text-align-last: center;
    .register-input {
        box-shadow: none;
        background-color: inherit;
        border: none;
        color: inherit;
        font-size: 14px;
        &:hover,
        &:focus,
        &.active {
            box-shadow: none;
            background-color: inherit;
            border: none;
            color: inherit;
        }
    }
}