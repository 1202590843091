.spinner {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    border: none;
    width: 100%;
    height: 100%;
    background-color:white;
    cursor: not-allowed;
    z-index: 999;
    flex: 1;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	color: #fff;
    font-size: 16px;
    
    img {
    	width: 100px;
	}
}