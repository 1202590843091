.report-container{
    margin-top: 2rem;
    button {
      background-color: #234caa;
      font-size: 14px;
      padding: 3px 5px;
      font-weight: 600;
    }
  .check-box{
    text-align: end;
  }
  .chart1{
    background-color: #ffffff;
    border-radius: 20px;
    padding: 22px;

  }
  .chart2{
    background-color: #ffffff;
    border-radius: 20px;
    padding: 22px;

  }
  .chart3{
    background-color: #ffffff;
    border-radius: 20px;
    padding: 22px;
    margin-top: 2rem;

  }
  .chart4{
    background-color: #ffffff;
    border-radius: 20px;
    padding: 22px;
    margin-top: 2rem;

  }
}