.questuin-settings{
    .header-container{
    h1{
        padding: 0px;
        font-weight: 500;
        font-size: 1.125rem;
        width: 100%;
        align-items: flex-start;
        display: flex;
    }
    .button-container{
        margin-top: 1rem;
        .create-button{
            font-size: 12px;
            padding-top: .5rem;
            padding-bottom: .5rem;
            padding-left: .75rem;
            padding-right: .75rem;
            border-radius: .375rem;
            font-weight: 500;
            cursor: pointer;
            background-color: #1c3faa;
            color: #ffffff;
            background-image: none;
            box-shadow: none;
            margin-left: 10px;
            border: none;
            &.back{
                background-color: #ffffff;
                color: #000000;
                background-image: none;
                box-shadow: none;
            }
            &.disabled {
                cursor: not-allowed;
                background-color: #7a91d8;
            }
        }
    }
}
.col{
    .bp3-form-group{
        .bp3-timepicker .bp3-timepicker-input-row{
            box-shadow: none !important;
            border-radius: 0.375rem !important;
            border: none !important;
            //background-color: #ffffff !important;
        }
        .bp3-input-group .bp3-input {
            position: relative;
            width: 100%;
            padding-top: 1.2rem !important;
            padding-bottom: 1.2rem !important;
            padding-left: 1rem !important;
            box-shadow: none !important;
            border-radius: 0.375rem !important;
            border: none !important;
            //background-color: #ffffff !important;
            padding-right: 1rem;
        }
        margin-top: 2rem;
        .bp3-label{
            font-weight: 600;
        }
    }
}
}