.container{
    width: 100%;
    margin: 0 auto;

    .inner-container-forgot-password{
        background-color: #ffffff;
        justify-content: center;
        width: 100%;
        align-items: center;
        min-height: 100vh;
        display: flex;
        .input-container{
            max-width: 625px;
			width:100%;
			margin: 10px;
            .image-container{
                margin-bottom: 20px;
                text-align: center;
                
            }
            h3{
                background-color:  #1c3faa;
                margin: -1px;
                color: #ffffff;
                padding: 10px;
                text-align: center;
                border-radius: 5px 5px 0 0;
                font-size: 20px;
            }
            .form-container{
                padding: 50px; 
                border-radius: 0 0 5px 5px;
                background-color: #f1f5f8;
            }
            .bp3-input-group.bp3-large .bp3-input{
                box-shadow: none;
            }
            .button-container{
                    display: flex;
                    width: 200px;
                    margin: auto;
                    background-color: #1c3faa;
                    border-radius: 5;
                    background-image: none;
                    color: #fff;
                    font-weight: bold; 
                    margin-top: 35px;
                    padding-top: .6rem;
                    padding-bottom: .6rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    border-radius: .375rem;
                    font-weight: 500;
                    cursor: pointer;
            }
            .error{
                color: #e42126;
            }
            .succes{
                color: green;
            }
        }
        .signin{
            color:#1a73e8;
            text-align: center;
            padding: 1px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
            a:hover{
                color: rgb(231, 56, 56);
                cursor: pointer;
                
            }
        }
    }
}