.za-chat {
    width: 401px;
    position: fixed;
    bottom: 0;
    right: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0px -2px 10px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 10px -1px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 10px -1px rgba(0,0,0,0.75);
    .header {
            padding: 10px;
            background-color: #1f45aa;
            color: #fff;
            margin-bottom: 0;
            margin: 0;
            svg {
                margin: 0 10px;
                cursor: pointer;
            }
    }
    .message-wrap {
        height: 0;
        &.expand {
            height: auto;
        }
        &.collapse {
            height: 0;
        }
        @keyframes expand {
            from {height: 400px;}
            to {height: auto;}
        }
        @keyframes collapse {
            from {height: 400px;}
            to {height: 0;}
        }
        .input-field {
            position: absolute;
            bottom: -15px;
            width: 401px;
        }
        .message-list {
            margin-bottom: 30px;
            height: 400px;
            overflow: hidden;
            overflow-y: scroll;
            padding: 20px;
            .rce-container-mbox {
                margin: 5px 0;
                .rce-mbox {
                    padding: 6px 9px 20px 9px;
                    max-width: 250px;
                    .rce-mbox-photo--img {
                        height: 100px;
                    }
                    .rce-mbox-time-block {
                        background: none;
                        color: #cecece;
                        bottom: -15px;
                    }
                    .rce-mbox-time {
                        color: #989898;
                        bottom: -15px;
                    }
                    .rce-mbox-file {
                        button {
                            max-width: 230px;
                        }
                    }
                }
                .rce-mbox-right {
                    background: #1f45aa;
                    color: #fff;
                    .rce-mbox-time {
                        color: #cecece;
                        bottom: -15px;
                    }
                    .rce-mbox-right-notch {
                        fill: #1f45aa;
                    }
                }
            }
            .bp3-spinner {
                float: right;
                margin-top: 15px;
                margin-left: 4px;
                margin-right: 10px;
            }
            .msg-sender{
                background-color: #2350aa;
                padding: 10px;
                border-radius: 12px;
                text-align: end;
                font-size: 15px;
                font-weight: 400;
                display: inline-block;
                margin: 5px;
                color: #fff;
                text-align: justify;
            }
            .msg-res{
                background-color: #82a6d65c;
                padding: 10px;
                border-radius: 12px;
                text-align: end;
                font-size: 15px;
                font-weight: 400;
                display: inline-block;
                margin: 5px;
                text-align: justify;
            }
        }
    }
    .file-preview-window {
        position: absolute;
        bottom: -30px;
        width: 321px;
        height: 460px;
        overflow: scroll;
        z-index: 9;
        .preview-wrapper, .react-pdf__Document {
            height: 401px;
            border: 1px solid #d3d3d3;
            margin: 0px;
            z-index: 9;
            position: relative;
            background-color: #fff;
            .preview-bar {
                display: none;
            }
            canvas {
                width: 401px !important;
                height: auto !important;
            }
        }
        .btn {
            position: fixed;
            z-index: 10;
            bottom: 0;
            right: 0;
            &.btn-danger {
                right: 130px;
            }
        }
    }
}