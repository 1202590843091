.featuremarkup-page {
    background: #F2F2F2;
    background: -moz-radial-gradient(center, #F2F2F2 0%, #A6A6A6 0%, #FFFFFF 100%);
    background: -webkit-radial-gradient(center, #F2F2F2 0%, #A6A6A6 0%, #FFFFFF 100%);
    background: radial-gradient(ellipse at center, #F2F2F2 0%, #A6A6A6 0%, #FFFFFF 100%);
   height: 100%;
   width:100%;
   padding: 5%;
  .features-container{
    padding-bottom: 5%;
    padding-top: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    .cardsitem {
        justify-content: space-evenly;
    }
    h1{
        text-align: center;
        padding-top: -1px;
    }
    .btncontainer {
      text-align: center;
      .btn{
background-color: #1f45aa;
border:none;
      }
      
    }
  }

}
