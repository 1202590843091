
.customwizard-container {
    .steps-button-top{
        bottom: 0;
        position: fixed;
        font-size: 15px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        color: #ffffff;
        z-index: 999;
        margin-bottom: 5px;
        &:hover,&.active {
            background-color: #1c3faa;
            color: #ffffff;
        }
        &.hide-it{
            opacity: 0;
        }
    }
    body:nth-child(1) {
        background-color: blue;
    }
    width: 100%;
    .quote {
    text-align: center;
    background: linear-gradient(to right, #e42126 0%, #5b0b0e 100%);
    opacity: 0.9;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    ol {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px 0px 0px 0px;
        align-content: space-evenly;
        counter-reset: step;
        li {
            color: #000000;
            width: 33.3%;
            line-height: 2.5rem;
            position: relative;
            text-align: center;
            list-style-type: none;          
            cursor: inherit;    
        }
        li span {
            padding: 0;
        }
        li:before {
            content: counter(step);
            counter-increment: step;
            width: 44px !important;
            height: 44px !important;
            text-align: center;
            margin:0 auto 18px auto;
            display: block;
            font-size: 20px;
            
            border-radius: 50%;
            border: 3px solid grey;
            background-color: white;           
            color: grey;
        } 
        li:after {
            content:'';
            position: absolute;
            width: 100%;
            height: 3px;
            top: 24%;
            left: -48%;
            background-color: #ddd;
            z-index:-1;
        }
        li:first-child:after {
            content: none !important;
        }
        li.active:before {
            background: #00E676;
            border: none;
            color: #ffffff;
            content: "✔";  
        }
        li.active + li:after {
            background: #00E676;
            color: #ffffff;
        }
        .doing {
            border-bottom: none;
            -webkit-background-clip: text;
            color:#ff6600;
        }
        .done {
            border-bottom: none;
            color:#018749;
        }
        .todo {
            border-bottom: none;
            color:#aaa4a4;
        }
        .done:before{
            content: "✔" !important; 
            border-bottom: none;
            padding: 3px;
            border: none;
            color: #ffffff;
            background: #A8EB1A;
            background: -moz-linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
            background: -webkit-linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
            background: linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
        }
        .doing:before {
            padding: 3px;
        }
        .doing:after,.done:after {
            background: #A8EB1A;
            background: -moz-linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
            background: -webkit-linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
            background: linear-gradient(45deg, #A8EB1A 0%, #298F57 100%);
        }
        .doing:before{
            border-bottom: none !important;
            background: rgb(255,102,0);
            background: -moz-linear-gradient(left, rgb(255,102,0) 0%, rgb(255,163,102) 50%, rgb(255,179,128) 100%);
            background: -webkit-linear-gradient(left, rgb(255,102,0) 0%, rgb(255,163,102) 50%, rgb(255,179,128) 100%);
            background: linear-gradient(to right, rgb(255,102,0) 0%, rgb(255,163,102) 50%, rgb(255,179,128) 100%);
            border: none;
            color: #ffffff;
        }
        .Question:before {
           font-family: fontAwesome;
           content: ' \F128 ';
           font-size: 20px;         
        }
        .Interview:before {
            font-family: fontAwesome;
            content: ' \F03D ';
            font-size: 20px;         
         }
         .Course:before {
            font-family: fontAwesome;
            content: ' \f19d ';
            font-size: 20px;         
         }
         .Mapping:before {
            font-family: fontAwesome;
            content: ' \F0E8 ';
            font-size: 20px;         
         }
         
    }   
    .navdiv {
        position: fixed;
        bottom: 0;
        background-color: transparent;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(0,0,0,0.5);
        opacity: 0.9;
        color: #fff;
        z-index: 999;
        padding: 10px;    
        .nextbtn:after {
            font-family: fontAwesome;
            padding-left: 5px;
            content: "\f101";
        }
        .prevbtn:before {
            font-family: fontAwesome;
            padding-right: 5px;
            content: "\f100";
        }
    }
    .wizard-container>:nth-last-child(1)>:nth-last-child(2) {
        padding: 50px 0;
    }
    .wizard-container>:nth-child(1) {
        text-align: center;
    }
}
.customwizard-container:nth-child(2)
{
    padding-bottom: 50px;
}