.NewWizard-container {
    width: 100%;
    .quote {
        text-align: center;
        background: linear-gradient(to right, #e42126 0%, #5b0b0e 100%);
        opacity: 0.9;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
    }
    