.course-page {
    width: 99%;
    margin: 0 38px;
    margin-top: 1rem;
    .title {
        padding: 0px;
        font-weight: 500;
        font-size: 1.125rem;
        width: 100%;
        align-items: flex-start;
        display: flex;
    }
    .form-control{
        height: calc(1.5em + .5rem + 2px);
        font-size: 0.9rem;
        border: 1px solid #ffffff;
    }
    .bp3-input-group .bp3-input{
        box-shadow: none;
        
}
.bp3-form-content{
    .error{
                color: red;
                float: left;
                font-size: 12px;
                margin-top: 5px;

    }
}
.bp3-form-group label.bp3-label{
    text-align: left;
}
    .btn-section {
        padding-right: 48px;
        .btn-prime {
                font-size: 12px;
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: .75rem;
                padding-right: .75rem;
                border-radius: .375rem;
                font-weight: 500;
                cursor: pointer;
                color: #000000;
                background-color: #ffffff;
                border: none;
                margin: 0 4px;
                box-shadow: 0 3px 20px #0000000b;
                &.save{
                    color: #ffffff;
                    background-color:#1c3faa;
                }
                
        }
      
        .icon-btn-section {
            padding-top: 10px;
            margin-right: 2px;
            border: none;
            .icon-btn-btn{
              //  font-size: 30px;
                padding: 8px;
                border: none;
                color: #696767;
                background-color:#ffffff;
                border-radius: 10px;
                outline: none;
                .icon-btn {
                    padding: 3px;
                    font-size: 22px;
                    color:#6c757d;
                    border: none !important;
                }
                &:focus {
                    border: none !important;
                    color: #E42126 !important;
                    //background-color: inherit;
                }
                &:hover {
                    // border: none !important;
                    color: #E42126;
                }
                &.active {
                    border: none !important;
                    color: #E42126;
                }
                .icon-btn_active {
                    padding: 3px;
                    font-size: 22px;
                    border: none !important;
                    color: #E42126;
                }
                &:focus {
                    border: none !important;
                    color: #E42126 !important;
                    background-color: inherit;
                }
                &:hover {
                    // border: none !important;
                    color: #E42126;
                }
                &.active {
                    border: none !important;
                    color: #E42126;
                }
                }
            }
        }
        .table-div {
            justify-content: space-evenly;
            width: 99%;
        }
        .table-class{
           // background-color: black;
        }
    // .import-button{
    //     // padding: 5px;
    //     // margin-bottom: 6px;
        
    // }
    .dropzone {
        flex: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
        cursor: pointer;
        &:hover {
            border-color: #2196f3;
        }
        .file-name {
            color: #28a745;
            align-self: flex-start;
        }
        &.active {
            border-color: #28a745;
        }
    }
    .import{
    .download-csv{
        align-self: center;
    }

    .upload-button{
        font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #0e1743;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        float: right;
        color: #ffffff;
        margin-top: 10px;
            
        }
    
}
    .horizontal-scroll {
        text-align: center;
        min-height: 100px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
       
    }
    .row{
        .col{
            .bp3-label{
                text-align: left;
                margin-left: 0px;
            }
            .error{
                color: red;
                float: left;
                font-size: 12px;
                margin-top: 5px;
            }
            .modal-body{
                background-color: #f2f2f2;
            }
            .option-text {
                outline: none;
                border: none;
                border-radius: 3px;
                box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
                background: #ffffff;
                height: 30px;
                padding: 0 10px;
                vertical-align: middle;
                line-height: 30px;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
                /* -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none; */
                &:focus,
                &:active{
                    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
                }
            }
            .modal-button{
                padding: 7px 18px;
                font-size: 14px;
                border: none;
                margin-left: 10px;
                background-color: #0e1743;
            } 
            .modal {
                background-color: rgba(000, 000, 000, .6);
            
                padding: 7px !important;
                &.show {
                    display: block !important;
                    opacity: 1 !important;
                }
                .modal-header {
                    background: #0e1743;
                    text-transform: uppercase;
                    padding:0 !important;
                    h6 {
                        color: #fff;
                        text-transform: uppercase;
                        width: 100%;
                        text-align: center;
                        margin-top: 13px;
                        margin-bottom: 11px;
                    }
                    // .close {
                    //     cursor: pointer;
                    //     color: #fff;
                    //     font-size: 1em;
                    //     font-weight: 400;
                    //     margin-top: -10px;
                    //     padding: 15px;
                    //     font-size: 1em;
                    //     padding: 2rem 2rem;
                    //     &:hover,
                    //     &:focus {
                    //         opacity: 1;
                    //         color: #fff !important;
                    //         border: none !important;
                    //         background-color: transparent;
                    //         box-shadow:none !important;
            
                    //     }
                    // }
                }
            }
            @media (min-width: 992px){
                .modal-lg {
                    max-width: 800px;
                }
            }
        }
    }
}