.filterInput {
    border-radius: 19px;
    // min-width: fit-content;
    .form-control {
        border-radius: 19px;   
        border-color:#234faa; 
        border: none;   
    }
    .input-group>.form-control:not(:last-child) {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
    }
    i{
        color: 'red';
    }
   
}
.filtered-icon {
    margin-top: -27px;
    margin-left: 169px;
    color: red;
    cursor: pointer;
    padding-right: 5px;
}