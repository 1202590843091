ResetPassword.container{
    width: 100%;
    margin: 0 auto;
    .inner-container{
        justify-content: center;
        width: 100%;
        align-items: center;
        min-height: 100vh;
        display: flex;
        .input-container{
            max-width: 625px;
			width:100%;
			margin: 10px;
            .image-container{
                margin-bottom: 20px;
                text-align: center;
                
            }
            h3{
                background-color:  #e42126;
                margin: -1px;
                padding: 10px;
                text-align: center;
                border-radius: 5px 5px 0 0;
                color: #fff;
                font-size: 20px;
            }
            .form-container{
                padding: 50px; 
                border-radius: 0 0 5px 5px;
            }
            .button-container{
                    display: flex;
                    width: 200px;
                    margin: auto;
                    background-color: #e42126;
                    border-radius: 5;
                    background-image: none;
                    color: #fff;
                    font-weight: bold; 
                    margin-top: 35px;
            }
            .reset-password{
                color: red;
            }
        }
    }
}