.visa-applied{
    .cas-applied-date{
        margin-bottom: 1.5rem;
    }
    .title{
        font-weight: 500;
        font-size: 16px;   
    }
    .text-box{
        margin-bottom: 10px;
    }
    .check-box{
        margin-left: 30px;
    }
    .save-offer{
        text-align: center;
        margin-top: 1rem;
        padding: 5px 26px;
        border: none;
        background-color: #02028e;
        font-weight: 500;
        &:hover{
            transform: scale(1.1);
            background-color: #46468e;
        }
    }
}