.new-design{
    background-color: #1f45aa;
    // width:250px;
    
   .left-side-image-container{
    height:424px;
    width: 299px !important;
    position: fixed;
   }
    .header{
        border-bottom: 1px solid #2f5ea9;
        padding: 22px 5rem;
        margin-bottom: 2rem;
  }
  .icons{
    font-size: 1.5rem;
    text-align: -webkit-right;
    color: #ffffff;
}
ul{
  list-style: none;
  .activated{
    background-color: #f1f5f8;
    cursor: pointer;
    color: #000000;
    padding: 14px 5rem 14px 1rem;
    width: 120%;
    border-radius: 20px;
    &.submenu{
      padding: 12px 18px;
      margin-left: 27px;
      margin-top: 6px;
      animation: slidein 2s ease 1 normal;
      @keyframes slidein {
        from {padding: 0px 18px;}
        to { padding: 12px 18px;}
    }

    }

  }
  .deactivated{
    background-color: transparent;
    color: #f1f5f8;
    padding: 18px 5rem 18px 1rem;
    width: 120%;
    cursor: pointer;
    &:hover{
      background-color: #f1f5f83f;
    border-radius: 20px;

    }
    &.submenu{
      padding: 12px 18px;
      margin-left: 27px;
      margin-top: 6px;
      animation: slidein 2s ease 1 normal;
    }
    @keyframes slidein {
      from {padding: 0px 18px;}
      to { padding: 12px 18px;}
  }
    
    
  }
}
.left-side-icon{
  font-size: 0.9rem;
    // position: absolute;
    // margin-left: 3.6rem;
    // margin-top: 17px;
    // &.interview{
    //   margin-top: 4.3rem;
    // }
    // &.questions{
    //   margin-top: 7.6rem;
    // }
    // &.course{
    //   margin-top: 10.7rem;
    // }
    // &.students{
    //   margin-top: 13.9rem;
    // }
    // &.members{
    //   margin-top: 17rem;
    // }
    // &.source{
    //   margin-top: 20.2rem;
    // }
    // &.channel{
    //   margin-top: 23.4rem;
    // }
   &.deactivate{
    color:#f1f5f8
    }
    &.drop{
      margin-right: 6px;
      font-size: 10px;
    }
}

  a{
    // padding: 1rem 7rem 16px 46px;
    // margin-left: 2.7rem;
    list-style: none;
    font-size: 15px;
    margin-left: 10px;
    // color: #ffffff !important;
    &.activate{
      background-color: #f1f5f8;
    color: #000000 !important;
    border-radius: 29px;
    }
  }

}