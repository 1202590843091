.contact-container{

    max-width: 1400px;
    margin: 0 auto;
 
    .form-group{
    padding: 35px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    border-radius: 10px;
        input{
            background-color: #e42126;
            border: none;
            border-radius: 0.4rem;
        }
        .button{
            background-color: #e42126;
        }
    
    }
 
}