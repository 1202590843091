.visa-appointment{
    .button-save{
        text-align: center !important;
        .save-offer{
            text-align: center !important;
            margin-top: 1rem;
            padding: 5px 26px;
            border: none;
            background-color: #02028e;
            font-weight: 500;
            &:hover{
                transform: scale(1.1);
                background-color: #46468e;
            }
        }
    }
    .visa-app-info{
        margin-top: 10px;
        margin-left: 10px;
        ul{
            padding: 0;
            list-style: none;
            li{
            font-size: 15px;
            line-height: 2;
            .iconImage{
            background-color: #fff;
            border: 1px solid silver;
            border-radius: 14px;
            margin-right: 5px;
            padding: 1px 2px;
            }
            .iconImageCheck{
            background-color: green;
            border: none;
            border-radius: 14px;
            margin-right: 5px;
            padding: 1px 2px;
            }
            .iconImageRefresh{
            background-color: blue;
            border: none;
            padding: 1px 2px;
            border-radius: 14px;
            margin-right: 5px;
            }
            }
            }
    }
}