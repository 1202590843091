.Mapping-page{
        .table-section{
            .table-class{
                .interest-block{
                    background-color: #fff;
                    padding: 10px;
                    .interest-button{
                        margin: 5px;
                    }
                }
            }
            .google-sync{
                margin-top: 10px;
            }
        }
    }  
    
