.student-interview-container{
    padding: 0px 25px 0px 25px;
    background-color: #ffffff;
    // width: 750px;
    height: fit-content;
    box-shadow: 1px 1px 20px lightgrey;
    font-size: 15px;
    margin-top: 4rem;
    .helper-text{
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 1rem;
        border-radius: 5px;
        text-align: center;
        background-color: whitesmoke;
    }
    .video{
        height: 370px;
        width: 300px;
    }
    .row{
        margin-top: 15px;
        .icons-container{
             justify-content: space-between;
             background-color: whitesmoke;
             height: 34px;
             text-align: center;
             padding: 9px 0px 2px 0px;
             border-radius: 5px;
             width: inherit;
        }
    }
    .abc{
        border-top: 1px solid #dedede;
        padding: 0px 0px 10px 32px;
        font-size: 13px;
    }
    .text-timing{
        text-align: center;
        padding-top: 6px;
    }
    .timer-content{
        font-size: 26px;
    }
    .skip-button{
        padding: 2px 7px;
        margin-left: 10px;
        font-size: 14px;
        color: cornsilk;
        background-color: #757575;
        border: none;
        margin-top: -12px;
    }
.zb-video-record {
    height: fit-content;
    .video-wrapper {
        position: relative;
        .header-wrapper {
            position: absolute;
            top: 0;
            z-index: 1;
            height: 250px;
            width: 350px;
            .question {
                padding: 6px 5px 15px 5px;
                background-color: rgba($color: #343a40, $alpha: 0.8);
                color: #fff;
                z-index: 1;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                border-radius: 20px;
            }
            .message-wrapper {
                padding:  5px;
                background-color: rgba($color: #343a40, $alpha: 0.8);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                font-size: 14px;
                color: #fff;
            }
            .timer {
                z-index: 1;
                width: 100%;;
            }
        }
        
        .myVideo-dimensions {
            height: 358px;
            width: 511px;
            border-radius: 20px;
        }
        .navigation {
            position: absolute;
            bottom: 5rem;
            padding: 0;
            background-color: transparent;
            color: #fff;
            z-index: 1;
            width: 100%;
            text-align: center;
            font-size: 1rem;
            button {
                background-color: rgba($color: #343a40, $alpha: 0.8);
                svg {
                    margin: 0 5px;
                }
            }
        }
    }
}

.row{
    margin-top: 15px;
    .icons-container{
         justify-content: space-between;
         background-color: whitesmoke;
         height: 34px;
         text-align: center;
         padding: 9px 0px 2px 0px;
         border-radius: 5px;
         width: inherit;
         margin-top:12px;
    }
}

.text-right{
    Button{
        padding: 8px 9px;
        font-size: 12px;
        border: none;
        font-weight: 600;
        &.next{
            background-color: #ee7261;
        }
    }
}
h5{
    padding: 1rem 2rem !important;
    margin-top: 2rem !important;
    color: #0551b3c7;
    font-weight: 700;
}
}
@media (max-width: 500px){
    .student-interview-container{
        .row .icons-container{
            // margin-left: 15px;
            width: initial;
        }
        h5{
            padding: 0 !important;

        }
      .abc{
            padding: 0;
        }
       .text-right Button.next{
           margin-bottom: 1rem;
           margin-top: 2rem;
       }
        // width: 380px;
        // height: 800px;
        margin-left: 15px;
        .video{
            height: 300px;
            // width: 350px;
        }
        .zb-video-record {
            .video-wrapper {
                height: 300px !important;
                width: 300px !important;
                .myVideo-dimensions {
                    height: 300px;
                    width: 300px;
                }
            }
        }
        .text-timing{
            text-align: left;
            padding-top: 6px;
        }
    }
}
  
  #canvas {
    width: 16px;
    left: 50%;
    height: 203px;
    top: 150px;
    margin-top: -127px;
    transform: rotate(90deg);
    margin-left: 18rem;
  }
  @media (max-width: 500px){
#canvas {
    width: 16px;
    left: 50%;
    height: 169px;
    top: 150px;
    margin-top: 21.3rem;
    transform: rotate(90deg);
    margin-left: 0rem;
    position: absolute;
}
  }