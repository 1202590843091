.activity-view {
    margin-bottom: 5px;
    .tab-activity-email{
        background-color: #bee5eb;
        .bp3-heading{
            color: #0c5460
        }
        .bp3-icon > svg  {
            color: #0c5460
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;
            .activity-create-name{
                color: #0c5460;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;
            .date-task-tag{
                background-color: #ffffffb3;
                color: #0c5460;
                font-weight: 600;
                float: right;
            }
         }
        .activity-course-name{
            color: #0c5460;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
    .edit-delete-row{
        float: right;
        margin-top: -23px;
        .note-status{
            font-size: 15px;
            color: #004085 ;
        }
        .whatsapp-status{
            font-size: 15px;
            color: #000000 ;
        }
    }
    .tab-activity-note{
        background-color:#cce5ff;
        .bp3-heading{
            color: #004085;;
        }
        .bp3-icon > svg  {
            color: #004085
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #004085;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color: #f1f7fe;
                color: #004085;
                font-weight: 600;
                float: right;
            }
         }
        .activity-course-name{
            color: #004085;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
    .tab-activity-inprogress{
        background-color:#fff3cd;
        .bp3-heading{
            color: #b6792c;;
        }
        .bp3-icon > svg  {
            color: #b6792c
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #b6792c;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color: #ffffffb3;
                color: #b6792c;
                font-weight: 600;
            }
         }
        .activity-course-name{
            color: #b6792c;
            font-weight: 600;
            margin-bottom: 5px; 
            margin-right: 10px;
        }

    }
    .tab-activity-whats_app{
        background-color: #89e68c0d;
        .bp3-heading{
            color: #42a846;
            margin-top: -25px;
        }
        .bp3-icon > svg  {
            color: #137517
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #137517;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color: #f1f7fe;
                color: #137517;
                font-weight: 600;
                float: right;
            }
         }
        .activity-course-name{
            color: #137517;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
    .whatsapp{
        margin-left: -33px;
        font-size: 1.7rem;
        color: #42a846; 
     }
    .tab-activity-completed{
        background-color:#d4edda;
        .bp3-heading{
            color: #225924;;
        }
        .bp3-icon > svg  {
            color: #225924
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #225924;
                font-weight: 600;
           }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color:#ffffffb3;
                color: #225924;
                font-weight: 600;
            }
         }
        .activity-course-name{
            color: #225924;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }

    }
    .tab-activity-pending{
        background-color:#f8d7da;
        .bp3-heading{
            color: #a0322e;;
        }
        .bp3-icon > svg  {
            color: #a0322e
        }
        .activity-info{
            mix-blend-mode: multiply;
        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #a0322e;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color:#ffffffb3;
                color: #a0322e;
                font-weight: 600;
            }
         }
        .activity-course-name{
            color: #a0322e;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }
       
    }
    .tab-activity-notereply{
        background-color:#c7f0d1;
        .bp3-heading{
            color: #225b29;;
        }
        .bp3-icon > svg  {
            color: #225b29
        }
        .activity-info{
            mix-blend-mode: multiply;
            overflow: auto;

        }
        .activity-creator{
            margin-top: 7.5px;;
            .activity-create-name{
                color: #225b29;
                font-weight: 600;
            }
        }
        .date-task{
            margin-top: 7.5px;;
            .date-task-tag{
                background-color: #f1f7fe;
                color: #225b29;
                font-weight: 600;
                float: right;
            }
         }
        .activity-course-name{
            color: #225b29;
            font-weight: 600;
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
    .field-wrapper{
        .date-task{
        //    margin-top: 7.5px;;
           .date-task-tag{
               background-color: #ffffff;
               color: #000000;
           }
        }
        .edit-update{
            float: right;
        }
        .edit-delete{
            float: right;
        }
        .select-options{
            padding: 0;
            height: 24px;
            font-size: 13px;
            // margin-top: 7.5px;;
        }
    }
}