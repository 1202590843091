.new-signup-markup{
    
    background-image: url(../../../assets/background.png);
    background-repeat: no-repeat;
    background-size: cover 100%;
    padding-top: 2rem;
    background-size: cover;
    position: relative;
    height: 125vh;
    margin-right: 0px;
    margin-left: 0px;
    width: '100%';
    margin: 0 auto;
    
    .outer-row{
        margin-left: 3rem;
    .left-side-login{
        padding-left: 3rem;
        .text-container-login{
            h1 {
              max-width: 70%;
            } 
            font-size: 16px;
            color: #ffffff;
            span{
                font-size: 35px;
                padding:18px;
            }
        }
        &.slide-right{
            width:100%;
            overflow:hidden;
          }
          &.slide-right{
            animation: 3s slide-right;
          }
        @keyframes slide-right {
            from {
              margin-left: -10rem;
            }
          
            to {
              margin-left: 0;
            }
          }
    }
    .login-box{
        min-height: 100vh;
        /* display: flex; */
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        margin-top: 2rem;
        min-height: 100vh;
        .form-group{
            margin-top: 2.5rem;
        .form-control{
            width: 65%;
            margin-top: 0.7rem;
            box-shadow: 0 3px 5px #00000007;
            padding: 19px;
            border: 1px solid #e8e8e8;
        }
        .error{
          font-size: 11px;
          padding: 3px;
          color:red;
        }
        .forgot-pass{
            text-align: center;
            font-size: 15px;
            padding: 18px;
            color: #4e596b;
        }
       .login-button {
           margin-top: 0.7rem;
        padding-top: .6rem;
        padding-bottom: .6rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        &.signup{
            margin-left: 1rem;
            color: #000000;
            background-color: #ffffff;
            border: 1px solid #e8e8e8;
        }
        }
    }
    .terms{
        margin-top: 1.5rem;
        padding: 10px;
        line-height: 21px;
        a{
            color: #1c3faa;
        }
    }
    &.slide-left{
        width:100%;
        overflow:hidden;
      }
      &.slide-left{
        animation: 3s slide-left;
      }
    @keyframes slide-left {
        from {
          margin-left: 15rem;
        }
      
        to {
          margin-left: 0;
        }
      }
    }
  

    }
}