.report-container{
    margin-top: 2rem;
    container {
        max-width: 100%;
    }
    button {
      background-color: #234caa;
      font-size: 14px;
      padding: 3px 5px;
      font-weight: 600;
    }
    .date-range-selector {
        .bp3-input-group {
            width: 130px;
        }
    }
    input {
        height: 36px !important;
    }
    .customer-selected-status {
        padding: 10px;
        .selected-status {
            border: 1px solid #7b7d7c;
            padding: 2px 5px;
            margin: 3px 10px;
            border-radius: 12px;
            i {
                color: #7b7d7c;
            }
        }
    }
    .customer-report{
        margin-left: 1px;
        .table-responsive-sm {
            overflow-y: auto;
            overflow-x: auto;
            height: 800px;
        }
    }
  
}