
.noDetails{
    text-align: center !important;
    text-align: -webkit-center;

    p{
        text-align: center;
        text-align: -webkit-center;
        color: brown;
        font-weight: 600;
        letter-spacing: 1px;

    }
}
