.email-composer {
    .email-editor {
        height: 230px;
        overflow: scroll;
    }
    .select-name{
        margin-bottom: 10px;
    }
    .email-btn-toolbar {
        padding: 6px 5px;
        border-radius: 2px;
        border: 1px solid #F1F1F1;
        display: flex;
        justify-content: flex-start;
        background: white;
        flex-wrap: wrap;
        font-size: 15px;
        margin-bottom: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}