.button-formatter {
    .icon-btn {
    padding: 0 3px;
    font-size: 25px;
    color: #355c88;
    &.edit{
        color: rgb(158, 112, 27);
    }
    &.delete{
        color: #E42126;
    }
    }
}