.activity-container{
    .top-container{
        margin-top: 1rem;
    }
    .card-container{
        margin-top: 2rem;
        .event-cards{
            border: 1px solid #dadada;
            box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.44);
            padding: 15px;
            border-top: 6px solid #a8a8a8;
            border-radius: 8px;
            .card-button{
                float: right;
                margin-top: -6px;
            }
            &:hover{
                transform: scale(1.1);
                cursor: pointer;
            }

        }
    }   
}