.education-section {
    margin-left: -15px;
    margin-right: -15px;
    .accordion{
      cursor: pointer;
      .icon{
        position: absolute;
        right: 21px;
        top: 24px;
      }
      .card{
          &:hover{
            background-color: #e9f3fb;
          }
          .card-body{
            &:hover{
                background-color: #ffffff;
              }
              span{
                  margin: 6px;
              }
              .inside-container{
                  &:hover{
                      text-decoration: underline;
                      color: rgb(25, 25, 158);
                  }
              .icon-inside{
                font-size: 22px;
              }
            }
          }
      }
    }
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    .academic {
        margin: 5px 0;
        border-top: 1px solid #d3d3d3;
        padding: 15px 0;
        .degree-type {
            font-size: 16px;
            font-weight: 600;
            margin: 5px 0;
        }
        .degree-name {
            font-size: 14px;
            font-weight: 600;
            color: #737171;
            margin: 5px 0;
        }
        .degree-score {
            font-size: 14px;
            font-weight: 600;
            margin: 5px 0;
        }
    }
}