
.result-container{
    .header {
        background-color: #ffffff;
        border-radius: 5px;
        text-align: end;
        h4 {
            margin: 0;
        }
        .student-back {
            font-size: 12px;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    padding-left: .75rem;
                    padding-right: .75rem;
                    border-radius: .375rem;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: #1c3faa;
                    border: none;
                    // margin-left: 10px;
        }
        .external{
            .external-icon{
                margin-right: 5px;
            }
        }
    }
    padding: 0;
    .student-name{
        background-color: #ffffff;
        margin-top: 1rem;
        padding: 15px;
        border: 1px solid rgba(138, 138, 138, 0.6);
        justify-content: center;
        .first-name{
            border: none;
            font-weight: 500;
            margin-bottom: 15px;
            .alternative-input{
                margin-left: 3px;
                margin-bottom: 5px;
                .alternative-email{
                    display: block;
                    width: 100%;
                    height: calc(1.5em + .75rem + 2px);
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: .25rem;
                    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .edit-pencil{
                    float: right;
                    cursor: pointer;
                    color: red($color: #000000);
                }
            }
            .errors{
                color: red;
                font-size: 11px;
            }
            
        }
        .add-icon{
            cursor: pointer;
        }
        .last-name{
            border: none;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }
  
    .education-row{
        // margin-top: 20px;
        background-color: #ffffff;
        margin-top: 1rem;
        padding: 22px;
        border: 1px solid rgba(138, 138, 138, 0.6);
    .education{
        .education-header{
            font-size: 1.4rem;
            font-weight: 500;
            max-width: 500px;
        }
        .plus-icon{
            float:right
        }
        i{
            float:right;
            font-size: 20px;
            color: #0073b1;
            cursor: pointer;
        }
        .education-add{
            margin-top: 2rem;
        .button1{
            font-weight: 600;
            border-radius: 2px;
            background-color: #0073b1;
            padding: 5px 20px;
            margin-right: 7px;
        }
        .description{
            h5{
                padding: 0;
                margin-left: -1rem;
            }
            .description-textarea{
                margin-left: -1rem;
            }
        }
        .info-row{
            margin-bottom: 1rem;
            .info-container{
                text-align: center;
                margin-bottom: 1rem;
                img {
                    // width: 43px;
                    // height: 45px;
                }
            }
            .info-doc{
                border: 0.5px solid #e6e3e3;
                .info-doc-row {
                    padding-left: 10px;
                    padding-top: 5px;
                    .info-des{
                        margin-bottom: 1rem;
                        padding: 0;
                        .info-read{
                            // padding-left: 10px;
                            // padding-right: 10px;
                        }
                        .info-down{
                            float: left;
                        }
                    }
                }               
            }
        }
       
    }
    .education-details-container{
            a {
                .button1 {
                    font-weight: 600;
                    border-radius: 2px;
                    background-color: #0073b1;
                    padding: 3px 8px;
                    margin-right: 7px;
                    &:hover {
                        background-color: #036296;
                    }
                }
            }

            margin-top: 2rem;
            h6 {
                font-size: 18px;
                padding: 0;
                margin: 0;
                font-weight: 600;
            }
            h3 {
                font-size: 16px;
                font-weight: 500;
                padding-top: 6px;
            }
            .education-row {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid #e8e8e8;
               
               
                .image-container {
                    text-align: center;
                    padding-top: 13px;
                    img {
                        width: 27px;
                        height: 31px;
                    }
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0;
                    margin: 0;
                    line-height: 1.3;
                    color: rgba(0, 0, 0, 0.6);
                }
                span {
                    color: rgba(0, 0, 0, 0.6);
                    font-size: 14px;
                }
                &.no-border{
                    border-bottom: none;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .icon-download{
                    font-size: 24px;
                    color: #0173b1;
                    padding: 0;
                    &:hover {
                        font-size: 28px;
                    }
                }
                .icon-new-edit{
                    font-size: 22px;
                    padding: 10px;
                    cursor: pointer;
                    color: #0073b1;
                    &:hover {
                        background-color: #e5f5fc;
                        border-radius: 100%;
                    }
                }
            }
        
    }
    }

    }
    .save-button{
        // float: left;
        flex-flow: row-reverse;
        .save{
            margin-top: 1rem;
            font-weight: 600;
            border-radius: 2px;
            background-color: #0073b1;
            // padding: 3px 8px;
            margin-right: 7px;
            
        }
    }
    .modal-body {
        max-height: 500px;
        overflow-y: auto;
    }

}