.proficiency-update-container{
    margin-top: -60px;
    background-color: #ffffff;
    border-radius: 10px;
h4{
    text-align: center;
}
p{
    padding-top: 1rem;
    font-weight: 600;
}
    form{
        margin-top: 4.5rem;
        .form-group{
            .error{
                color: rgb(230, 14, 14);
                font-size: 13px;
              }
            margin-bottom: 1.5rem;
        .form-label{
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 4px;
        }
        .form-input-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
        .form-textarea-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
        
    }
    .button-delete{
        float: left;
        background-color: transparent;
        color: rgba(0,0,0,.6);
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.6);
        padding: .2rem 0.8rem;
        border-radius: 2px;
        border:  0.4px solid #969292;
        margin-bottom: 10px;
        font-weight: 600;
        width: 100%;

        &.save{
            background-color: #0073b1;
            color: #ffffff;
            float: right;
            border: none;
            box-shadow: none;
        }
    }
    }
&.wizard{
    width: 50%;
}
    
    
}