.members-markup {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-top: none !important;
    padding: 15px 15px 0px 20px;
    border-radius: 0;
    a {
        font-size: 12px;
    }
    .editbtn {
        margin-top: 7px;
        .icon-btn {
            color :#646262;
            padding: 0 5px;
            font-size: 25px;
            color: #9e701b;
            cursor: pointer;
        }

    }
}
