.student-page{
.student-title{
    margin-top: 1.2rem;
    font-size: 15px;
    text-align: end;
}
.card-header-title{
    color: #000000;
    background-color: transparent;
    border: none;
    margin-top: 1rem;
.row-class{
    padding: 1px 1.25rem;
    margin-top: 8px;
}
.text-right{
    margin-bottom: 5px;    
}
.right-side-search{
    margin-top: 1rem !important;
    text-align: right !important;
}

.title{

    font-size: 0.875rem;
}
}
.import-button{
    padding: 6px 9px;
    font-size: 12px;
    border: none;
    margin-top: 1rem;
    margin-left: 5px;
    border-radius: .375rem;
    font-weight: 500;
    cursor: pointer;
    color: #000000;
    background-color: #ffffff;
    border: none;
    margin-top: 1rem;
    box-shadow: 0 3px 20px #0000000b;
}
.btn.disabled, .btn:disabled{
    opacity: 0.5;
}
.search-button{
    padding: 6px 9px;
    font-size: 12px;
    border: none;
    margin-left: 5px;
    // margin-top: 1rem;
    // margin-top: 1rem;
    margin-left: 5px;
    border-radius: .375rem;
    font-weight: 500;
    cursor: pointer;
    color: #000000;
    background-color: #ffffff;
    border: none;
    // margin-top: 1rem;
    box-shadow: 0 3px 20px #4643430b;

}
.create-button{
    padding: 6px 9px;
    font-size: 12px;
    border: none;
    margin-left: 5px;
    margin-top: 1rem;
    margin-top: 1rem;
    margin-left: 5px;
    border-radius: .375rem;
    font-weight: 500;
    cursor: pointer;
    color: #ffffff;
    background-color: #234faa;
    border: none;
    margin-top: 1rem;
    box-shadow: 0 3px 20px #4643430b;
}

.page-header{
    padding-top: 1rem;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: 500;
        margin-left: 1rem;
}
.dropzone {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
    &:hover {
        border-color: #2196f3;
    }
    .file-name {
        color: #28a745;
        align-self: flex-start;
    }
    &.active {
        border-color: #28a745;
    }
}
.download-csv{
    align-self:center;
}
.bp3-input{
        background-color: #d2dfea;
        border-radius: 19px;
        box-shadow: none;
    
}
.upload-button{
    font-size: 12px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: .75rem;
    border-radius: .375rem;
    font-weight: 500;
    cursor: pointer;
    background-color: #0e1743;
    border: none;
    margin-left: 10px;
    background-image: none;
    box-shadow: none;
    float: right;
    color: #ffffff;
    margin-top: 10px;
}
.righ-side-filter{
    text-align: right;
    margin-top: 1rem;
    background-color: #d2dfea;
    border-radius: 19px;
    box-shadow: none;
    font-size: 13px;
    
}
.right-side-search-class{
    text-align: right;
    padding-right: 20px;
}
.left-side-search-class {
    text-align: left;
    margin-top: 1rem;

}
.applied-filter {
    // border: 1px solid #234faa;
    min-height: 55px;
    padding: 0 10px;
    margin-top: 10px;
    // background-color: #ffffff;
    margin: 0 10px;
    .filter {
        margin: 10px 10px;
        padding: 0 10px;
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;
    }
    .delete-icon {
        color: grey;
        padding-left: 10px;
        cursor: pointer;
    }
    .resetall {
        color: #ff471a;
        cursor: pointer;
    }
}

.modal{
    z-index: 20;
}
}
.popover-title-h3 {
    background-color: #234faa;
    text-align: center;
    color: #ffffff;
}
.popover{
    max-width: 442px !important;
}
// .bs-popover-bottom>.arrow::after {
//     background-color: #234faa;
// }
.bs-popover-bottom>.arrow::after {
border-bottom-color: #234faa;
}