.drag-drop-container{
    .bp3-card{
        border-radius: 10px;
    }
    .bp3-input {
        position: relative;
        width: 79%;
        padding-left: 1rem !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
        border: none !important;
        background-color: #ffffff !important;
        padding-right: 1rem;
    }
.bp3-button{
    &.createQuestions{
        font-size: 13px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        border: none;
        background-image: none;
        color: #ffffff;
    }
    &.hideQuestions{
        background-color: gray !important;
        background-image: none;
        color: white;
        float: right;
    }
    &.search-button{
        padding: 6px 9px;
        font-size: 12px;
        border: none;
       
        margin-left: 5px;
    
    }
}
}