.registerinput{
    border: none;
    width: 20%;
    box-shadow: 0 0 0 0 rgba(19,124,189,0), 0 0 0 0 rgba(19,124,189,0), inset 0 0 0 1px rgba(16,22,26,.15), inset 0 1px 1px rgba(16,22,26,.2); 
}
.eye-icon-btn{
    margin-top: 38px;
    margin-left: -20px;
    /* border: 1px solid #e8e8e8;
    position: absolute;
    top: 34%;
    padding: 14px;
    margin-left: -45px; */
    background-color: #f9f8f8;
    z-index: 999;

}