.form-generator {
   .bp3-popover-arrow{
        display: none !important;
    }
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    .error {
        color: #dc3545
    }
    .remove{
        margin-top: 2rem;
        background-color: #ffb5b5;
    }
    .select-class{
        max-height: 185px;
        contain: content;
        overflow-y: auto;
    }

    .bp3-menu{
        max-height: 185px;
        contain: content;
        overflow-y: auto;
    }
    
    .field-wrapper {
        margin: 8px 0;
        .textarea.bp3-input, textarea.bp3-input.bp3-large, textarea.bp3-input.bp3-small {
            height: 100px !important
        }
        .bp3-label {
            margin-bottom: 10px;
        }
     
        .bp3-menu{
            max-height: 185px;
            contain: content;
            overflow-y: auto;
          
        }
  
        .bp3-popover-target {
            width: 100%;
            button {
                width: 100%;
            }
            .bp3-menu{
                max-height: 185px;
                contain: content;
                overflow-y: auto;
            }
        }
        .external-icon{
            float: right;
            margin-right: -23px;
            font-size: 19px;
            margin-top: 4px;
            cursor: pointer;
        }
        .dropzone {
            align-items: center;
            border-width: 2px;
            border-radius: 2px;
            border-color: #eeeeee;
            border-style: dashed;
            background-color: #fafafa;
            color: #000000;
            outline: none;
            transition: border .24s ease-in-out;
            max-height: 29px;
            min-height: 29px;
            border: 1px solid #000000;
            cursor: pointer;
            &:hover {
                border-color: #2196f3;
            }
            .file-name {
                color: #28a745;
                align-self: flex-start;
            }
            &.active {
                border-color: #28a745;
            }
          
        }
    }
}

.bp3-select-popover{
    .bp3-menu{
        max-height: 185px;
        contain: content;
        overflow-y: auto;
    }
}