.notification-show{
    position: fixed;
    height:400px;
    width: 300px;
    background-color: #ffffff;
    right: -29px;
    border-radius: 5px;
    box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.4);
    z-index: 1;
  
    p{
        padding: 10px;
        text-align: center;
        border-bottom: 0.2px solid #4b4b4b
    }
    .title-notification{
        text-align: center;
        border-radius: 5px 5px 0px 0px;
        padding: 10px;
        background-color: #e42126;
        color: #ffffff;
      }
      .notification-hide{
        opacity:0;
      }
  }

 
 