
.result-container{
    .send-button-offer{
       margin-top: 1rem;
    }
    .marks-header{
        font-size: 19px;
    }
    .status-card{
        padding: 7px;
        width: 100% !important; 
        margin: 0 !important;
        text-align: end;
        .bp3-card{
            padding: 6px;
            width:66%;
            font-weight: 600;

        margin-left: 6.7rem;
        &.eligible{
            background-color: #3c8c46;
            color: #ffffff;
        }
        &.not-eligible{
            background-color: #f54d4d;
            color: #ffffff;
        }
        }
    } 
    .head-buttons{
        margin-top: 8px;
        Button{

            padding: 6px 9px;
            font-size: 12px;
            background-color:  #b42a3c;
            border: none;
            &.back{
               margin-left: 12px;
            }
            &.save{
                background-color: #3c8c46;
            }
            &.edit{
                    background-color: #c56c06;
            }
        }
   
    }
     
    h4{
        
        margin-top: 0.7rem;
    }
    h2{
        text-align: center;
        background-color:   #263a4e;
        color: #fff;
    }
    hr{
        background-color: #000000;
        box-sizing: content-box;
        overflow: visible;
    }
    h5{
        padding: 1rem; 
    }
    .span-title{
        padding: 6px;
    }
    h6{
            padding: 6px; 
            .icon-font-pdf{
                font-size: 1rem;
                color: #b42a3c;
            }
            &.value-align{
                padding: 1rem 5rem;
            }
    }
    p{
        padding: 0 2rem;
    }
    .reject{
        background-color: #246fa5;
        margin-top: 12px;
        font-size: 0.8rem;
        border: none;
        &.point{
            cursor: default;
            box-shadow: none;
    }
    }
    .status-card{
        width: 38%;
        text-align: center;
        margin: 10px 15rem
    }
    .condition{
        background-color: #8b4298;
        margin-top: 12px;
        font-size: 0.8rem;
        border: none;
        &.point{
            cursor: default;
            box-shadow: none;
    }
    }
    .icon-div{
        top: 0.7rem;
        .interview-name-button{
            margin-top: 7px;
            margin-bottom: 15px;
        .link-interview{
            margin-top: 8px;
            padding: 5px;
            font-size: 11px;
            margin-left: 4px;
            border: none;
            background-color: #b42a3c;
            box-shadow:0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2)
        }
        .cross-icon{
            font-size: 0.9rem;
            margin: 0 8px;
            color: #e20033;
            cursor: pointer;
        }
      
    }
        .video-file{
            font-size: 1.6rem;
            color: #8d48ce;
            cursor: pointer;
            &:hover{
                color: rebeccapurple;
            }
        }
        .video-interview-name{
            padding: 8px;
            font-weight: 500;
        }
        .send-button{
           
            padding: 6px 17px;
            font-size: 12px;
            margin: 4px 0;
            background-color:  #b42a3c;
        }
        .video-play-icon{
            margin-bottom: 1rem;
        }
        .interview-date{
            padding: 0;
            margin-top: 16px;
        }
    }

    .academic-team{
        background-color: #c56c06;
        margin-top: 12px;
        font-size: 0.8rem;
        border: none;
        &.point{
            cursor: default;
            box-shadow: none;
    }
    }
    .uncondition{
        background-color: #5f964e;
        margin-top: 12px;
        font-size: 0.8rem;
        border: none;
        &.point{
            cursor: default;
            box-shadow: none;
    }
    }
    .icon-font-pdf{
        cursor: pointer;
        font-size: 1.4rem;
        color: #b42a3c;

    }
    .requirement-error{
        color: #b42a3c;
    }
    
    .icon-font-tick{
        font-size: 1rem;
        color: #0b9121;
    }
    .bottom-div{
        margin-left: -1rem;
    }
    .interview-icon{
        font-size:14px;
        cursor: pointer;
        color:white;
        background-color: #238C2C;
        padding: 0 15px;
        font-weight: 500;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
        
    }
    .frame{
        width: 100%;
        height: 28rem;
    }
    .input-box{
        width: 3.25rem;
        height: 1.5rem;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .video-react-video{
        width: 100% !important;
        padding: 0!important;
    }
    .model-button{
        margin-top: 6px;
    }
    .video-react .video-react-big-play-button {
        top: 6rem;
        left: 12rem;
    }
}