.container{
    width: 100%;
    margin: 0 auto;

    .inner-container-forgot-password{
        background-color: #ffffff;
        justify-content: center;
        width: 100%;
        align-items: center;
        min-height: 100vh;
        display: flex;
        .input-container{
            max-width: 625px;
			width:100%;
			margin: 10px;
            h3{
                background-color:  #1c3faa;
                margin: -1px;
                color: #ffffff;
                padding: 10px;
                text-align: center;
                border-radius: 5px 5px 0 0;
                font-size: 20px;
            }
              p {
                background-color:  #1c3faa;
                margin: -1px;
                color: #ffffff;
                padding: 10px;
                text-align: center;
                border-radius: 5px 5px 0 0;
                font-size: 20px;
            }
              
              
              /* loading dots */
              
              .loading:after {
                content: ' .';
                animation: dots 1s steps(5, end) infinite;}
              
              @keyframes dots {
                0%, 20% {
                  color: rgba(0,0,0,0);
                  text-shadow:
                    .25em 0 0 rgba(0,0,0,0),
                    .5em 0 0 rgba(0,0,0,0);}
                40% {
                  color: white;
                  text-shadow:
                    .25em 0 0 rgba(0,0,0,0),
                    .5em 0 0 rgba(0,0,0,0);}
                60% {
                  text-shadow:
                    .25em 0 0 white,
                    .5em 0 0 rgba(0,0,0,0);}
                80%, 100% {
                  text-shadow:
                    .25em 0 0 white,
                    .5em 0 0 white;}}
              
        }
    }
}