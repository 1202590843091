.Mapping-page {
    width: 100%;
    margin: 0 auto;
    margin-top:2rem;
    .title {
        font-size: 1.125rem;
        width: 100%;
        align-items: flex-start;
        display: flex;
    }    
    table tr td:first-child::before {
        content: none;
    }
    .bp3-input-group .bp3-input{
        box-shadow: none;
    }
    .btn-section {
        padding-right: 30px;
        .btn {
            font-size: 15px;
            padding-top: .5rem;
            padding-bottom: .5rem;
            padding-left: .75rem;
            padding-right: .75rem;
            border-radius: .375rem;
            font-weight: 500;
            cursor: pointer;
            background-color: #1c3faa;
            border: none;
        }
    }
    .table-section {
        justify-content: center;
        align-items: flex-start;
        min-height: 100vh;
        margin-top: 20px;
        border-radius: 10px;
        .table-class {
            justify-content: center;
            border-radius: 10px;            
            .table-head {
                text-align: center;
                text-justify:auto;
                background: 1c3faa;
                border: none;
                opacity: 0.9;
                font-size: 16px;
                color: #000000;
                font-weight: unset;
                border: none;
                width: 200px;
                padding: 10px 5px;
                }
            .table-row{
                padding: 2px !important; 
                vertical-align: auto !important;
                text-align: center;
                text-justify:auto;
                height: 40px;
                font-size: 15px;
                color: rgb(100, 98, 98);
                font-weight: unset;
                border: none;
                width:auto;
                box-shadow: none;
                &:hover,
                &:focus,
                &.active {
                    text-align: center;
                    text-justify: auto;
                    font-size: 15px;
                    color: gray;  
                    line-height: 1.2;
                    font-weight: unset;
                   // background-color: #f5f5f5;
                    cursor: pointer;
                }
            }
        }
        
    } 
}


