@import url('https://fonts.googleapis.com/css?family=Lato');

.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    right: 5px;
    bottom: 35px;
    .countdown-item {
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 15px;
        margin: 5px;
        padding-top: 5px;
        position: relative;
        width: 35px;
        height: 30px;
    }
    
    .countdown-item span {
        color: #fff;
        font-size: 7px;
        font-weight: 600;
        text-transform: uppercase;
    }
    
    .countdown-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
    }
    
    footer {
        background-color: #222;
        color: #fff;
        font-size: 14px;
        bottom: 0;
        position: fixed;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
    }
    
    footer p {
        margin: 10px 0;
    }
    
    footer i {
        color: red;
    }
    
    footer a {
        color: #3c97bf;
        text-decoration: none;
    }
}