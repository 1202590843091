.left-drawer{
    position: fixed;
    left:0;
    top: 0;
    height: 92%;
    width:45px;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.4);
    .inner-container-drawer{
        a{
            color: #5c7080 !important;
        }
        padding: 0px 15px;
        margin-top: 53px;
        span{
            padding: 14px 0;
        }
    }

  }
  .tool-tip-drawer{
    font-size: 15px;
    padding:5px;
    font-weight: 600;
}