.lineBreak .bp3-text-muted{
    color: #7c98b6;
    display: block;
}

.warring .bp3-text-muted{
    color: #ff0000;
}

.warring .bp3-form-helper-text{
    color: #ff0000;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
}