.outer-div-app{
  
}
.zb-container.true {
  margin-top: 24px;
  border-radius: 19px;
  min-height: 93vh;
  padding-bottom: 0.1rem;
  background-color: #f1f5f8;
  padding-left: 10px;
}
.right-app-container{
  /* margin-left: -68px; */
  padding-left: 0;
  background-color: #1f45aa;
}
.right-top-container-markup{
  height: 3.8rem;
  background-color: #f1f5f8;
  padding: 10px;
  border-bottom: 1px solid #dfe1e2;
  border-radius: 10px;
  }
.left-app-container{
  padding-right: 0px;
}
.zb-navigation {
  background-color: #e42126;
  opacity: 0.9;
  color: #fff;
 
}
.bp3-icon{
  cursor: pointer;
}
.bp3-tree-node{
  cursor: pointer;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bp3-drawer-header .bp3-heading{
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  .icon-btn {
  padding: 0.3px;
  font-size: 25px;
  }
  .wizard {
    display: none;
  }

