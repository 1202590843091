.conditional{
    text-align: center;
    .save-offer-button{
        text-align: center !important;
        .save-offer{
            text-align: center !important;
            margin-top: 1rem;
            padding: 5px 26px;
            border: none;
            background-color: #02028e;
            font-weight: 500;
            &:hover{
                transform: scale(1.1);
                background-color: #46468e;
            }
        }
    }
   
}