.modal {
    background-color: rgba(000, 000, 000, .6);

    padding: 7px !important;
    &.show {
        display: block !important;
        opacity: 1 !important;
    }
    .modal-body{
        background-color: #f1f5f8;
    }
    .modal-body-document{
        height: 25rem;
    }
    .modal-header {
        background: #0073b1;
        text-transform: uppercase;
        padding:0 !important;
        h6 {
            color: #fff;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            margin-top: 13px;
        }
        .close {
            cursor: pointer;
            color: #fff;
            opacity: .8;
            font-size: 1em;
            font-weight: 400;
            margin-top: -10px;
            padding: 15px;
            font-size: 1em;
            padding: 2rem 2rem;
            &:hover,
            &:focus {
                opacity: 1;
                color: #fff !important;
                border: none !important;
                background-color: transparent;
                box-shadow:none !important;

            }
        }
    }
    .search-button{
        padding: 6px 9px;
        font-size: 12px;
        border: none;
        margin-left: 5px;
        margin-top: 1rem;
        margin-top: 1rem;
        margin-left: 5px;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        color: #000000;
        background-color: #ffffff;
        border: none;
        margin-top: 1rem;
        box-shadow: 0 3px 20px #4643430b;
    
    }
    .righ-side-filter{
        text-align: right;
        margin-top: 1rem;
        background-color: #d2dfea;
        border-radius: 19px;
        box-shadow: none;
        font-size: 13px;
        min-width: max-content;        
    }
}
@media (min-width: 992px){
    .modal-lg {
        max-width: 800px;
    }
}