.interview-setting{
    h1{
        padding: 0px;
        font-weight: 500;
        font-size: 1.125rem;
        width: 100%;
        align-items: flex-start;
        display: flex;
    }
.text-right { 
   
    
    .bp3-button{
            margin-top: 10px;
            margin-left: 5px;
            padding: 6px 9px;
            font-size: 12px;
            border: none;
    }
    .primary-buttons{
        font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        &.back{
            background-color: #ffffff;
            color: #000000;
            background-image: none;
            box-shadow: none;
        }
    }
}

}
.interview-setting-inputs{
    margin-top: 2rem;
    .bp3-input-group .bp3-input {
        position: relative;
        width: 100%;
        padding-top: 1.2rem !important;
        padding-bottom: 1.2rem !important;
        padding-left: 1rem !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
        border: none !important;
        background-color: #ffffff !important;
        padding-right: 1rem;
    }
}

