.contact-container{

    max-width: 1400px;
    margin: 0 auto;

    .form-group{
    padding: 35px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    border-radius: 10px;
        input{
            background-color: #f0f0f0;
            border: none;
            border-radius: 0.4rem;
        }
        textarea{
            background-color: #f0f0f0;
            border: none;
        }
    
    }
    .contents{
        h2{
    color: #4b4b4b;
    font-size: 1.86667rem;
    line-height: 1.0;
    letter-spacing: .02em;
    font-weight: 300;
        }
        h1{
            margin-top: 6rem;
           font-size: 2.8rem;
            color: #4b4b4b;;

        }
        p{
            font-size: 1.2rem;
            color: #4b4b4b;;
        }
        .za{
            background:#80d4ff;
            opacity: 1;
            color: white;
            border: 1px solid white;
            width: 15rem;
            border-radius: 0.4rem;

        }

        Button{
            background:#80d4ff;
            color: white;
            border: 1px solid white;
            width: 7rem;
            border-radius: 0.4rem;
            
        }
       
    }
}