.slide {
  .righ-side-filter{
    // text-align: right;
    margin-bottom: 1rem;
    background-color: #d2dfea;
    border-radius: 19px;
    box-shadow: none;
    font-size: 13px;  
}
.disable-filter{
  margin-bottom: 1rem;
  border-radius: 19px;
  box-shadow: none;
  font-size: 13px; 
  background-color: #e9ecef;
  cursor: not-allowed;

}
  .search-button {
    color: #ffffff;
    background-color: #234faa;
  }
  .form-control {
    padding-left: 0.25rem;
  }
  .start-date-label{
    margin-top: 0.5rem;
    font-size: 15px;
  }
    .start-date{
          .bp3-input{
            margin-bottom: 1rem;
            background-color: #d2dfea;
            border-radius: 19px;
            box-shadow: none;
            font-size: 13px;
            text-align: center;
          }
    }
    .bp3-input-group .bp3-input{
      // margin-bottom: 1rem;
      background-color: #d2dfea;
      border-radius: 19px;
      box-shadow: none;
      font-size: 13px;
    }
  
}