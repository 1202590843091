.export-page{
    .export-view{
        .export-create{
            justify-content: center;
        }
        .p{
            font-size: 13px;
        }   
    }
    .download-file{
        justify-content: center;
    }
}
