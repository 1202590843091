.table-container{
   &.widthset {
        width: fit-content;
   }
    .table-section {
        // justify-content: center;
        // align-items: flex-start;
        // display: flex;
        margin-top: 20px;
        border-radius: 10px;
        padding: 0;
        .table-class {
            border-radius: 10px;
            .table-head {
                text-align: center;
                text-justify:auto;
                height: 30px;
                font-size: 0.875rem;
                color: #000000;
                font-weight: unset;
                border: none;
                width: 200px;
                padding: 10px 5px;
                }
            .table-head1 {
                text-align: center;
                text-justify:auto;
                height: 30px;
                // background: #e42126;
                font-size: 0.875rem;
                color: #000000;
                font-weight: unset;
                border: none;
                width: 25px;
                padding: 10px 5px;
                }
            .table-row{
                // padding: 2px !important; 

            border-top: 10px solid #f1f5f8;
                vertical-align: auto !important;
                background-color: #ffffff;
                text-align: center;
                text-justify:auto;
                height: 40px;
                font-size: 15px;
                color: rgb(100, 98, 98);
                font-weight: unset;
                width:auto;
                border-radius: 20px !important;
                &:hover,
                &:focus,
                &.active {
                    // border: none; 
                    height: 30px;
                    text-align: center;
                    text-justify: auto;
                    font-size: 15px;
                    color: gray;  
                    line-height: 1.2;
                    font-weight: unset;
                    
                    cursor: pointer;
                }
                .editable {
                    input {
                        background-color: #fff;
                    }
                }
            }
        }
       
    }
}
.thclass {
    th {
        min-width: 200px;
    }
    .numcont{
        text-align: center;
        min-width: 100px;
    }
}