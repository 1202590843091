.questions-page {
    width: 100%;
    margin: 0 auto;
    
    .title {
        &.question{
            font-weight: 500;
            font-size: 1.125rem;
            justify-content: flex-start;
        }
        font-weight: bold;
        width: 100%;
        justify-content: flex-end;
        display: flex;
        margin-top: 20px;
            .btn {
                font-size: 12px;
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: .75rem;
                padding-right: .75rem;
                border-radius: .375rem;
                font-weight: 500;
                cursor: pointer;
                background-color: #1c3faa;
                border: none;
                margin-left: 10px;
                &.back{
                    background-color: #ffffff;
                    color: #000000;
                    background-image: none;
                    box-shadow: none;
                }
            } 
        
    }
}