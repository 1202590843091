.education-section {
    .view-button{
        margin-left: 10px;
        padding: 0px 6px;
        border: none;
        background-color: #d4edda;
        font-weight: 400;
        color:#155724;
    }
    
    .save-button{
        margin-top: 1rem;
        padding: 5px 26px;
        border: none;
        background-color: #02028e;
        font-weight: 500;
        align-items: center;
        &:hover{
            transform: scale(1.1);
            background-color: #46468e;
        }
    }
    .apply-button{
        margin-top: 1rem;
        padding: 5px 26px;
        border: none;
        background-color: #02028e;
        font-weight: 500;
        &:hover{
            transform: scale(1.1);
            background-color: #46468e;
        }
    }
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    .applied {
        margin: 5px 0;
        // border-top: 1px solid #d3d3d3;
        padding: 15px 0;
        background-color: #f1f5f8;
        .degree-type {
            font-size: 16px;
            font-weight: 600;
            margin: 5px 0; 
            margin-left: 10px;    
        }
        .degree-name {
            font-size: 14px;
            font-weight: 600;
            color: #737171;
            margin: 5px 0;  
            margin-left: 10px;   
        }
       
        .degree-score {
            font-size: 14px;
            font-weight: 600;
            margin: 5px 0;
            margin-left: 10px;
        }
        .select-options{
            padding: 0;
            height: 24px;
            font-size: 13px
        }
        .add-icon{
            padding-left: 0;
            .icon-btn{
                font-size: 20px;
                color: #28a745;
            }
        }
        .application-number{
            .application-input{
                font-size: 14px;
                font-weight: 600;
                color: #737171;
                padding: 12px;
                .app-view{
                    font-weight: 500;
                    color: black;
                }
            }
        }
    }
    .modal-body {
        max-height: 500px;
        overflow-y: auto;
    }
}