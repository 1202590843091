.interviews-page {
    
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
   .count-title{ p {
        margin-bottom: 10px;
        margin-top: 0;
        font-weight: 100;
        font-size: 15px;
        color: #718096;
    }}
    .title {
        padding: 0px;
        font-weight: 500;
        font-size: 1.125rem;
        width: 100%;
        align-items: flex-start;
        display: flex;
        .btn {
                /* position: absolute;
                right: 0px;
                left: 87px; */
                font-size: 12px;
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: .75rem;
                padding-right: .75rem;
                border-radius: .375rem;
                font-weight: 500;
                cursor: pointer;
                background-color: #1c3faa;
                border: none;
        }
    }
}