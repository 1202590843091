.dashboard-container{
    .card-container{
        .check-box{
            margin-top: 1rem;
            text-align: end;
        }
        .cards{
            background-color: #ffffff;
            padding: 30px 24px;
            border-radius: .375rem;
            box-shadow: 0px 4px 20px -9px #c7c7c7;
            cursor: pointer;
            transition: transform 0.5s;
            background: -webkit-gradient(linear, left top, right top, from(#01a9ac), to(#01dbdf));
            background: linear-gradient(to right, #01a9ac, #01dbdf);
           
            &:hover{
            transform: scale(1.1);
            }
            &.today{
                background: -webkit-gradient(linear, left top, right top, from(#0ac282), to(#0df3a3));
                background: linear-gradient(to right, #0ac282, #72a795);
            }
            &.hot{
                background: -webkit-gradient(linear, left top, right top, from(#fe5d70), to(#fe909d));
                background: linear-gradient(to right, #fe5d70, #fe909d);
            }
            &.week{
                background: -webkit-gradient(linear, left top, right top, from(#fe9365), to(#feb798));
                background:linear-gradient(to right, #5961af, #8e8eff);
            }
            .icon{
                font-size: 30px;
                margin-bottom: 13px;
                color: #ffffff;

            }
            h3{
                font-weight: 700;
                color: #ffffff;
            }
            p{
                color: #7b7b7b;
                color: #ffffff;
                font-weight: 600;

            }
        }
    }
    .charts-containers{
        margin-top: 2rem;
        .chart-one{
                background-color: #ffffff;
            box-shadow: 0px 4px 20px -9px #c7c7c7;
            padding: 11px;
            border-radius: .375rem;
            transition: transform 0.5s;
            // &:hover{
            // transform: scale(1.05);
            // }
            .chart{
            width: 100%;

            }

                
        }
        .chart-two{
            background-color: #ffffff;
        box-shadow: 0px 4px 20px -9px #c7c7c7;
        padding: 11px;
        border-radius: .375rem;
        // margin-top: 10px;
        transition: transform 0.5s;
            &:hover{
            transform: scale(1.05);
            }

    }
    .chart-avg{
        background-color: #ffffff;
        box-shadow: 0px 4px 20px -9px #c7c7c7;
        padding: 11px;
        border-radius: .375rem;
        transition: transform 0.5s;
    }
    }
}