.zloader {
    z-index: 9999;
    .bp3-overlay-backdrop {
        background-color: rgba(16, 22, 26, 0.4);
        
    }
    .spinner {
       background-color: transparent;
       background-color: rgba(27, 22, 22, 0.8);;
        .bp3-spinner-head {
            stroke: rgba(228, 33, 38, 0.8)
        }
    }
}