.university-assessment{
    .assessment-label{
        font-size: 16px;
        font-weight: 500;
        .assessment-option{
            font-size: 14px;
            font-weight: 400;
            margin-left: 30px;
        }
    }
    .button-save{
        text-align: center;
    .save-offer{
        text-align: center;
        margin-top: 1rem;
        padding: 5px 26px;
        border: none;
        background-color: #02028e;
        font-weight: 500;
        &:hover{
            transform: scale(1.1);
            background-color: #46468e;
        }
    }
    }
    .errors{
        color: rgba(238, 0, 0, 0.979);
        font-size: 11px;
    }
}