.mapped-connect-markup{
  .navbar-brand {
    color : #000000;
    padding: 10px 0;
    font-family: roboto;
    font-weight: 900;
    font-size: 2.5rem;
    position:absolute;
    top:50%;
    right:7%;
    transform:translate(-50%,-50%);
    display:flex;
    .title{
        border-top: 5px solid #db3931;
        font-size: 2.5rem;

    padding: 10px 0;
    font-family: roboto;
    font-weight: 900;
    }
    .sub {
        font-size: 0.7rem;
        color : #000000;
    }
}
img{
    position:absolute;
    top:50%;
    left:25%;
    transform:translate(-50%,-50%);
    display:flex;

  }
  p{
    position:absolute;
    top:70%;
    font-size: 26px;
    font-weight: 500;
    left:50%;
    transform:translate(-50%,-50%);
    display:flex
  }
    .loader {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      display:flex;
    }
    .loader span {
      width:20px;
      height:20px;
      background:white;
      margin:0px 1px;
      animation:loading 2000ms ease-in-out infinite;
    }
    .loader span:nth-child(1) {
      background:#eca02e;
      animation-delay:-2600ms;
    }
    .loader span:nth-child(2) {
      background:#eade3d;
      animation-delay:-2500ms;
    }
    .loader span:nth-child(3) {
      background:#ddc53e;
      animation-delay:-2400ms;
    }
    .loader span:nth-child(4) {
      background:#ec902e;
      animation-delay:-2300ms;
    }
    .loader span:nth-child(5) {
      background:#09b7bf;
      animation-delay:-2200ms;
    }
    .loader span:nth-child(6) {
      background:#ea3d8c;
      animation-delay:-2100ms;
    }
    
    .loader span:nth-child(7) {
      background:#ea3d8c;
      animation-delay:-2000ms;
    }
    .loader span:nth-child(8) {
      background:#ea3d8c;
      animation-delay:-1900ms;
    }
    .loader span:nth-child(9) {
      background:#ea3d8c;
      animation-delay:-1800ms;
    }
    .loader span:nth-child(10) {
      background:#ea3d8c;
      animation-delay:-1700ms;
    }
    .loader span:nth-child(11) {
      background:#2eeca8;
      animation-delay:-1600ms;
    }
    .loader span:nth-child(12) {
      background:#eade3d;
      animation-delay:-1500ms;
    }
    .loader span:nth-child(13) {
      background:#55acee;
      animation-delay:-1400ms;
    }
    .loader span:nth-child(14) {
      background:#ec902e;
      animation-delay:-1300ms;
    }
    .loader span:nth-child(15) {
      background:#09b7bf;
      animation-delay:-1200ms;
    }
    .loader span:nth-child(16) {
      background:#5fb87d;
      animation-delay:-1100ms;
    }
    
    .loader span:nth-child(17) {
      background:#bd819c;
      animation-delay:-1000ms;
    }
    .loader span:nth-child(18) {
      background:#d4762a;
      animation-delay:-900ms;
    }
    .loader span:nth-child(19) {
      background:#ca4932;
      animation-delay:-800ms;
    }
    .loader span:nth-child(20) {
      background:#c01a1a;
      animation-delay:-700ms;
    }
    .loader span:nth-child(21) {
      background:#5fb87d;
      animation-delay:-600ms;
    }
    
    .loader span:nth-child(22) {
      background:#bd819c;
      animation-delay:-500ms;
    }
    .loader span:nth-child(23) {
      background:#d4762a;
      animation-delay:-400ms;
    }
    .loader span:nth-child(24) {
      background:#ca4932;
      animation-delay:-300ms;
    }
    .loader span:nth-child(25) {
      background:#c01a1a;
      animation-delay:-200ms;
    }
    
    @keyframes loading {
      0%,60%,100% {
        transform:scaleY(0.05);
      }
      35% {
        transform:scale(1);
      }
    }
  }