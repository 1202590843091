.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
      span{
          font-size: 1.16rem;
      }
      p {
          font-size: 1.16rem;
      }
      .contact{
          padding: 2rem;
      }
      .contact-icon {
          display: inline;
      }
      .footer-class {
          background-color: #212529;
          .footer-content {
              color: #ffffff;
              text-align: center;
              align-items: center;
              display: flex;
              h3{
                  font-family: roboto;
                  font-weight: 900;
                  font-size: 2rem;
              }
              .footer-subpart {
                  padding : 7px;
                  text-align: center;
                  .icons{
                      font-size: 1.25rem;
                      margin-left: 7px;
                      color: #ffffff;
                      &:hover{
                        font-size: 1.15em;
                        opacity: 0.7;
                      }
                  }
              }
              
          }
      }
  }
  