.members-container {
    .header-card { 
        background-color: transparent;
        border: none;
        .row-class{
        padding: 1px 1.25rem;
        margin-top: 8px;
    }
    .title{
        font-size: 0.875rem;
        font-weight: 500;
    }}
    .top-button {
        padding-bottom: 20px;
        button {

            font-size: 12px;
            padding-top: .5rem;
            padding-bottom: .5rem;
            padding-left: .75rem;
            padding-right: .75rem;
            border-radius: .375rem;
            font-weight: 500;
            cursor: pointer;
            background-color: #1c3faa;
            border: none;
            background-image: none;
            box-shadow: none;
            color: #ffffff;
            margin-top: 10px;
        }
    }
}