.table-component-container{
    .text-input{
        padding-top: 1.2rem !important;
        padding-bottom: 1.2rem !important;
        padding-left: 1rem !important;
        box-shadow: none !important;
        border-radius: 0.375rem !important;
        border: none !important;
        background-color: #ffffff !important;
        padding-right: 1rem;
        margin-bottom: 2rem;
       
    }
    .form-group {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        .form-check{
            .form-check-input{
                width: 30px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    .table thead{
        text-align: center;
        color: #000000;
        background-color: transparent;
    }
    .table td{
        text-align: center;
        padding: 3px;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #ffffff;
        border-top: 10px solid #f1f5f8;
    }
    .table th{
        text-align: center;
        padding: 10px 5px;
        font-size: 0.875rem;
        font-weight: 400;
        border:none;
    }
    h1{
        font-size: 1.125rem;
        margin-bottom: 2rem;
    }
    span{
        font-size: 16px;
        font-weight: 400;
    }
    .button-container{
        text-align: end;
    .save-button{
        font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #1c3faa;
        border: none;
        margin-left: 10px;
        background-image: none;
        box-shadow: none;
        color: #ffffff;
        i {
            font-weight: 500;
        }
    }
}
}
@media (max-width: 500px){
.table-component-container{
    width: 80%;
    margin-left: 2.8rem;
    height: 600px;
    h1{
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }
    .button-container{
        margin-right: -42px;
    }
}
}