.home-search-wrap {
    .home-search-wrap-container {
        .bp3-card {
            .bp3-form-group {
                .bp3-text-muted {
                    color: #ff0000;
                }
            }
        }
    }
}
.bp3-form-group {
    .bp3-text-muted {
        color: #ff0000;
    }
}
.error-msg{
    color: #e42126;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}
