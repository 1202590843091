.experience-update-container{
    input{width: 100% !important;}
    .error{
        padding: 5px;
        color: red;
        font-size: 11px;
      }
    margin-top: -60px;
    background-color: #ffffff;
h4{
    text-align: center;
}
    form{
        margin-top: 4.5rem;
        label{
            padding-right: 16px;
            padding-top: 10px;
        }
        .date-picker{
           
            margin-bottom: 1rem;
            width: 100%;
            height: 40px;
        //   border-color:  #ced4da;
          border:0.5px solid #ced4da;
          border-radius: 5px;
        }
        .react-date-picker__wrapper{
            border-radius: 5px;
            border: 1px solid #ccc;
        }
        .form-group{
            margin-bottom: 1.5rem;
        .form-label{
            padding-right: 16px;
            padding-top: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 4px;
        }
        .form-input-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
        .form-textarea-field{
            border-radius: 0.2rem;
            padding: 1.3rem 1.1rem 1.3rem;
            border: 1px solid rgba(0,0,0,.2);
        }
        
    }
    .button-delete{
        float: left;
        background-color: transparent;
        color: rgba(0,0,0,.6);
        box-shadow: inset 0 0 0 1px rgba(0,0,0,.6);
        padding: .2rem 0.8rem;
        border-radius: 2px;
        border:  0.4px solid #969292;
        margin-bottom: 10px;
        font-weight: 600;
        width: 100%;

        &.save{
            background-color: #0073b1;
            color: #ffffff;
            float: right;
            border: none;
            box-shadow: none;
        }
    }
    }
    &.wizard{
        width: 50%;
    }
}
