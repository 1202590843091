
    .profile-section {
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        .avatar {
            width: 85px;
            height: 85px;
            border-radius: 50%;
        }
        .name {
            font-weight: bold;
            font-size: 16px;
            text-transform: capitalize;
        }
        .education {
            color: #737171;
        }
        .email {
            font-size: 14px;
            margin-top: 5px;
        }
        button {
            font-size: 12px;
            margin: 10px 5px 10px 0;
            svg {
                margin: 0 5px 0 0;
            }
        }
    }