.visa-applied{
    .applied-date{
        margin-right: 10px;
        margin-top: 4px;
        margin-bottom: 0;
    }
    .button-save{
        text-align: center;
    .save-offer{
        text-align: center;
        margin-top: 1rem;
        padding: 5px 26px;
        border: none;
        background-color: #02028e;
        font-weight: 500;
        &:hover{
            transform: scale(1.1);
            background-color: #46468e;
        }
    }
    }
}