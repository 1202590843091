.application-create-page{
    .application-form{
        margin-bottom: 1rem;
        // text-align-last: center;
        .bp3-input-group .bp3-input{
            width: 140%;
        }
        .select-class{
            margin-bottom: 1rem;
        }
        .app-clear-button{
            //margin: 1rem;
            padding: 5px 26px;
            border: none;
            background-color: #007bff;
            font-weight: 500;
            margin-left: 10rem;
            margin-top: 1rem;
        }
        .app-apply-button{
           // margin: 1rem;
            padding: 5px 26px;
            border: none;
            background-color: #007bff;
            font-weight: 500;
            margin-left: 1rem;
            margin-top: 1rem;
        }
    }
    
}