table tr:nth-child(1){
    text-align: center;
    counter-reset: rowNumber;
  }
  table tr {
    counter-increment: rowNumber;
  }
  table td {
    
    vertical-align: middle !important;
  }
  table tr td:first-child::before {
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
  }