.cardpage { 
    padding-top: 5px;
    
    .Features-card { 
        width: 125px;
        height: 200px;
        text-align: center;
        img {
            max-height: 100%;
        }    
        .name{
            color:#008ae6;
            font-size: 20px;
            text-align: center;
            padding: 5px;
        }
        .details {
            color:#595959;
            font-size: 15px;
            text-align: center;
            padding: 5px;
        }
    .SelectedText {
        color: rgb(32, 209, 9);
    }
       
    }
    .selected {        
        border: 2px solid green;        
    }
    .selected:before {
        // color: green;
        // content: "✔"; 
        // font-size: 20px;
        // border: 2px solid green;   
    }    
    .selectedcard {        
     //   border: 2px solid green;
      //  border-bottom-style: quote($string: "Feature Added");
    }   
}
