.head{
    padding-top: 1rem;
        width: 100%;
        background-color: #f1f5f8;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 1rem;
    h3{
        font-size: 1.125rem;
    }
    .card-container{
        margin-top: 20px;
    }
    .head-text{
        color: rgb(5, 47, 87);
        cursor: pointer;
        &:hover{
            color: rgb(182, 29, 29);
        }

    }
    .bp3-input{
        background-color: #d2dfea;
        border-radius: 19px;
        box-shadow: none;
    }
    .text-center{
        width: 100%;
        font-weight: bold;
        font-size: medium;
    }
}
.application-link {
    margin: 20px 10px;
    padding: 10px;
    border: 1px solid #d3d3d3;
    background-color: #fff;
    svg {
        margin-top: 10px;
        cursor: pointer;
    }
}
.card-step-wrap {
    background: #fff;
    margin: 5px 0;
    border-radius: 5px;
    .cards-step {
        height: 20px;
        width: 20px;
        background-color: #6c757d;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.75);
        border-radius: 50%;
        margin: 20px auto;
        &.inprogress {
            background-color: #ffc107;
        }
        &.completed {
            background-color: #28a745;
        }
    }
    .cards-step-text {
        margin-bottom: 10px;
    }
    .cards-step-line {
        height: 3px;
        width: 100%;
        background: #000;
        margin-top: 28px;
    }
}
.character-container {
    position: fixed;
    bottom: 0;
    left: -200px;
    z-index: 1;
    &.animate {
        -webkit-animation: characterMove 0.5s forwards;
        -webkit-animation-delay: 1s;
        animation: characterMove 0.5s forwards;
        animation-delay: 1s;
    }
    &.animate-two {
        -webkit-animation: characterMove 2s forwards;
        -webkit-animation-delay: 2s;
        animation: characterMove 2s forwards;
        animation-delay: 2s;
    }
    img {
        height: 300px;
        width: auto;
    }
    .cloud {
        width: 300px;
        height: 200px;
        display: table;
        background-color: #000;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20% 20% 20% 0%;
        box-shadow: 0 0 0 5px #000;
        position: absolute;
        bottom: 275px;
        left: 80px;
        right: 0;
        margin: auto;
        padding: 20px;
        text-align: left;
        opacity: 0;
        -webkit-animation: characterMessage 0.5s forwards;
        -webkit-animation-delay: 1.5s;
        animation: characterMessage 0.5s forwards;
        animation-delay: 1.5s;
        &.next {
            left: 110px;
            bottom: 300px;
        }
      }
}

.dropzone {
    padding: 5px;
    min-height: 100px;
    border:1px solid #0f1a43
}
@keyframes characterMove {
    100% {left: 20px;}
}
@keyframes characterMessage {
    0% {opacity: 0;}
    25% {opacity: 0.25;}
    50% {opacity: 0.5;}
    75% {opacity: 0.75;}
    100% {opacity: 1;}
}

