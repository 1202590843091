.paginationButton{
    margin: 15px;
    text-align: center;
    .rc-pagination > li {
        padding: 0 10px;
        margin: 4px;
    }
   
    .rc-pagination-item-active {
        background-color:#1f45aa;
        border-color: #1f45aa;
        color:white;
        .rc-pagination-item a {
            text-decoration: none;
            
          }
      }
    .rc-pagination-item:hover {
        border-color:#1f45aa;
      }
    .rc-pagination-item:hover a {
        color:#1f45aa;
      }
      .rc-pagination-item:focus a{
          color:#fff;
      }
    .rc-pagination-jump-prev:hover:after,
    .rc-pagination-jump-next:hover:after {
        color:#1f45aa;
      }
      .rc-pagination-jump-prev button:hover:after {
        content: '«';
      }
      .rc-pagination-jump-next button:hover:after {
        content: '»';
      }
      .rc-pagination-jump-prev button:after {
        content: '«';
      }
      .rc-pagination-jump-next button:after {
        content: '»';
      }
}