.source-container{
    .source-button{
        font-size: 12px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-left: .75rem;
        padding-right: .75rem;
        border-radius: .375rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #234faa !important;
        border: none;
        margin-left: 10px;
      
    }
    .errors {
        color: #e42126;
        margin-top: 5px;
        font-size: 12px;
    } 
    .source-top-row{
        .source-create{
            padding-top: 1rem;
            width: 100%;
            margin: 0 auto;
            border-radius: 10px;
            margin-bottom: 1rem;
            font-size: 1.125rem;
            font-weight: 500;
            margin-left: 1rem;
        }
        .source-create-button{
                font-weight: bold;
                width: 100%;
                justify-content: flex-end;
                display: flex;
                margin-top: 8px;
                margin-bottom: 11px;
                margin-right: 20px;
                    .source-button{
                        font-size: 12px;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                        padding-left: .75rem;
                        padding-right: .75rem;
                        border-radius: .375rem;
                        font-weight: 500;
                        cursor: pointer;
                        background-color: #234faa !important;
                        border: none;
                        margin-left: 10px;
                      
                    } 
        
            
        }
    }
   
    .source{
        margin-bottom: 7px;
        .source-row{
            .city-name{
                padding: 0;
            }
            padding: 13px;
            .source-action{
                .trash{
                
                    cursor: pointer;
                }
                .edit{
                    // margin-left: -25px;
                    margin-right: 15px;
                    cursor: pointer;

                }
            }
        }
    }
}

